define("shared/components/modal-confirm-imported-upgrade/component", ["exports", "shared/mixins/modal-base", "shared/components/modal-confirm-imported-upgrade/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    layout: _template.default,
    classNames: ['medium-modal'],
    btnCB: Ember.computed.alias('modalService.modalOpts.btnCB'),
    actions: {
      confirm() {
        const {
          btnCB
        } = this;
        this.modalService.modalOpts.finish(this.close.bind(this), false, btnCB);
      },

      cancel() {
        const {
          btnCB
        } = this;
        this.modalService.modalOpts.finish(this.close.bind(this), true, btnCB);
      }

    },

    close() {
      this.send('close');
    }

  });

  _exports.default = _default;
});